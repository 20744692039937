/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPoll = /* GraphQL */ `
  query GetPoll($id: ID!) {
    getPoll(id: $id) {
      id
      name
      order
      status
      questions {
        items {
          id
          text
          type
          order
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          options {
            items {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule
      relationId
      relation {
        id
        type
        extId
        polls {
          items {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      results {
        items {
          id
          time
          data
          isPreview
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPolls = /* GraphQL */ `
  query ListPolls(
    $id: ID
    $filter: ModelPollFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPolls(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        name
        order
        status
        questions {
          items {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        schedule
        relationId
        relation {
          id
          type
          extId
          polls {
            items {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        results {
          items {
            id
            time
            data
            isPreview
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getQuestion = /* GraphQL */ `
  query GetQuestion($id: ID!) {
    getQuestion(id: $id) {
      id
      text
      type
      order
      pollId
      poll {
        id
        name
        order
        status
        questions {
          items {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        schedule
        relationId
        relation {
          id
          type
          extId
          polls {
            items {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        results {
          items {
            id
            time
            data
            isPreview
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      options {
        items {
          id
          text
          order
          questionId
          question {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          responses {
            items {
              id
              optionId
              respondentId
              isPreview
              option {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listQuestions = /* GraphQL */ `
  query ListQuestions(
    $id: ID
    $filter: ModelQuestionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listQuestions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        text
        type
        order
        pollId
        poll {
          id
          name
          order
          status
          questions {
            items {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule
          relationId
          relation {
            id
            type
            extId
            polls {
              items {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          results {
            items {
              id
              time
              data
              isPreview
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        options {
          items {
            id
            text
            order
            questionId
            question {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            responses {
              items {
                id
                optionId
                respondentId
                isPreview
                option {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOption = /* GraphQL */ `
  query GetOption($id: ID!) {
    getOption(id: $id) {
      id
      text
      order
      questionId
      question {
        id
        text
        type
        order
        pollId
        poll {
          id
          name
          order
          status
          questions {
            items {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule
          relationId
          relation {
            id
            type
            extId
            polls {
              items {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          results {
            items {
              id
              time
              data
              isPreview
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        options {
          items {
            id
            text
            order
            questionId
            question {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            responses {
              items {
                id
                optionId
                respondentId
                isPreview
                option {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      responses {
        items {
          id
          optionId
          respondentId
          isPreview
          option {
            id
            text
            order
            questionId
            question {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            responses {
              items {
                id
                optionId
                respondentId
                isPreview
                option {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listOptions = /* GraphQL */ `
  query ListOptions(
    $id: ID
    $filter: ModelOptionFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listOptions(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        text
        order
        questionId
        question {
          id
          text
          type
          order
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          options {
            items {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        responses {
          items {
            id
            optionId
            respondentId
            isPreview
            option {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getResponse = /* GraphQL */ `
  query GetResponse($id: ID!) {
    getResponse(id: $id) {
      id
      optionId
      respondentId
      isPreview
      option {
        id
        text
        order
        questionId
        question {
          id
          text
          type
          order
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          options {
            items {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        responses {
          items {
            id
            optionId
            respondentId
            isPreview
            option {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listResponses = /* GraphQL */ `
  query ListResponses(
    $id: ID
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listResponses(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        optionId
        respondentId
        isPreview
        option {
          id
          text
          order
          questionId
          question {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          responses {
            items {
              id
              optionId
              respondentId
              isPreview
              option {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPollResult = /* GraphQL */ `
  query GetPollResult($id: ID!) {
    getPollResult(id: $id) {
      id
      time
      data
      isPreview
      pollId
      poll {
        id
        name
        order
        status
        questions {
          items {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        schedule
        relationId
        relation {
          id
          type
          extId
          polls {
            items {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        results {
          items {
            id
            time
            data
            isPreview
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listPollResults = /* GraphQL */ `
  query ListPollResults(
    $id: ID
    $filter: ModelPollResultFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPollResults(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        time
        data
        isPreview
        pollId
        poll {
          id
          name
          order
          status
          questions {
            items {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule
          relationId
          relation {
            id
            type
            extId
            polls {
              items {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          results {
            items {
              id
              time
              data
              isPreview
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRelation = /* GraphQL */ `
  query GetRelation($id: ID!) {
    getRelation(id: $id) {
      id
      type
      extId
      polls {
        items {
          id
          name
          order
          status
          questions {
            items {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule
          relationId
          relation {
            id
            type
            extId
            polls {
              items {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          results {
            items {
              id
              time
              data
              isPreview
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listRelations = /* GraphQL */ `
  query ListRelations(
    $id: ID
    $filter: ModelRelationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listRelations(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        extId
        polls {
          items {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const responseByOption = /* GraphQL */ `
  query ResponseByOption(
    $optionId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelResponseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    responseByOption(
      optionId: $optionId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        optionId
        respondentId
        isPreview
        option {
          id
          text
          order
          questionId
          question {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          responses {
            items {
              id
              optionId
              respondentId
              isPreview
              option {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const resulgByPoll = /* GraphQL */ `
  query ResulgByPoll(
    $pollId: ID!
    $time: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPollResultFilterInput
    $limit: Int
    $nextToken: String
  ) {
    resulgByPoll(
      pollId: $pollId
      time: $time
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        time
        data
        isPreview
        pollId
        poll {
          id
          name
          order
          status
          questions {
            items {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule
          relationId
          relation {
            id
            type
            extId
            polls {
              items {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          results {
            items {
              id
              time
              data
              isPreview
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
