import Vue from 'vue'
import VueRouter from 'vue-router'
import VueCookies from 'vue-cookies'
import VueMoment from 'vue-moment'
import App from './App.vue'
import Amplify from 'aws-amplify'
import awsmobile from './aws-exports'
import {
  applyPolyfills,
  defineCustomElements,
} from '@aws-amplify/ui-components/loader'
import VueHtml2pdf from 'vue-html2pdf'


import Poll from './components/Poll.vue'
import ResultsPdf from './components/ResultsPdf.vue'
import ResultsContent from './components/ResultsContent.vue'
import OpeningSoon from './components/OpeningSoon.vue'
import NotFound from './components/NotFound.vue'

import store from './state/index'

Amplify.configure(awsmobile)
applyPolyfills().then(() => {
  defineCustomElements(window)
})

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.use(VueCookies)
Vue.use(VueMoment)
Vue.use(VueHtml2pdf)

function guardRoute (to:any, from:any, next:any|undefined) {
  const pollId = to.params.id
  store.dispatch('InitPollWatch', pollId)
  if (to.name.toLowerCase().indexOf('preview') !== -1) {
    store.dispatch('SetPreview', true)
  }
  if (!store.getters.getPoll) {
    store.dispatch('FetchPoll', pollId).then(() => {
      if (store.getters.getPoll === 404 || (store.getters.getPoll?.status !== 'published' && store.getters.isPreview === false)) {
        if (next) {
          next('/404')
        }
      } else {
        redirectIfResponded(to, pollId, next)
      }
    })
  } else {
    redirectIfResponded(to, pollId, next)
  }
}

function redirectIfResponded(to: any, pollId:string, next:any|undefined) {
  store.dispatch('InitRespondentId').then(() => {
    const hasResponded = Vue.$cookies.get(pollId + store.state.isPreview)
    // redirect to results if the user has responded, or if the poll has closed.
    if (store.state.isPreview === true) {
      next()
    } else if ((hasResponded && to.name === 'Poll') || new Date().getTime() > (store.state?.poll as any)?.schedule?.close_time) {
      store.commit('setRespondentId', hasResponded)
      if (next) {
        next('/' + to.params.id  + '/results')
      }
    } else if(to.name !== 'Opening Soon' && new Date().getTime() < (store.state?.poll as any)?.schedule?.open_time) {
      if (next) {
        console.log('should go to opening soon')
        next('/'  + to.params.id  + '/opening-soon')
      }
    } else if(next) {
      next()
    }
  })
}

function prepareResults(to: any, next: any, pollId: string) {
  if (to.name.toLowerCase().indexOf('results') !== -1 && !store.getters.getResult) {
    store.dispatch('FetchPollResults', {pollId}).then(() => {
      next()
    })
  } else {
    next()
  }
}

const routes = [
  {
    path: '/404',
    name: 'Not Found',
    component: NotFound
  },
  {
    path: '/:id',
    name: 'Poll',
    component: Poll,
    beforeEnter: guardRoute
  },
  {
    path: '/:id/opening-soon',
    name: 'Opening Soon',
    component: OpeningSoon,
    beforeEnter: guardRoute
  },
  {
    path: '/:id/preview',
    name: 'PreviewPoll',
    component: Poll,
    beforeEnter: guardRoute
  },
  {
    path: '/:id/results',
    name: 'Results',
    component: ResultsContent,
    beforeEnter: (to: any, from: any, next: any) => {
      guardRoute(to, from, (nxt: string|undefined) => {
        if (nxt && nxt === '/404') {
          next(nxt)
        } else {
          prepareResults(to, next, to.params.id)
        }
      })
    }
  },
  {
    path: '/:id/results/pdf',
    name: 'Results',
    component: ResultsPdf,
    beforeEnter: (to: any, from: any, next: any) => {
      guardRoute(to, from, (nxt: string|undefined) => {
        if (nxt && nxt === '/404') {
          next(nxt)
        } else {
          prepareResults(to, next, to.params.id)
        }
      })
    }
  },
  {
    path: '/:id/results/preview',
    name: 'PreviewResults',
    component: ResultsContent,
    beforeEnter: (to: any, from: any, next: any) => {
      guardRoute(to, from, (nxt: string|undefined) => {
        if (nxt && nxt === '/404') {
          next(nxt)
        } else {
          prepareResults(to, next, to.params.id)
        }
      })
    }
  },
  {
    path: '*',
    component: NotFound
  }
]

const router = new VueRouter({
  routes // short for `routes: routes`
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
