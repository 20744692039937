












import { Component, Prop, Vue, Watch } from 'vue-property-decorator'

@Component({
  computed: {
  }
})
class Result extends Vue {
  @Prop() question: any
  @Prop() color: string | undefined
  viewboxDimensions = [0, 0, 100, 0]
  barHeight = 4
  textHeight = 5
  combinedHeight = 0
  barMargin = 1
  barMarginRight = 5

  @Watch('question')

  mounted () {
    this.combinedHeight = this.barHeight + this.textHeight
    // viewbox width 100 height 10 * rows
    const numOpts = this.question.options.items.length
    this.viewboxDimensions[3] = (numOpts * this.combinedHeight) + (numOpts * this.barMargin)
  }

  get bars () {
    const options = this.question.options.items
    const bars = []
    const largestResponse = Math.max(...options.map((o:any) => o.response))
    for (const [i, option] of options.entries()) {
      const currentYPos = (i * this.combinedHeight) + (i * this.barMargin)
      // -- width is response / largest * 100
      const text = {
        y: currentYPos + (this.textHeight * .75),
        text: option.text
      }
      let barWidth =  ((option.response / largestResponse) * 100)
      if (barWidth === 0) {
        barWidth = 2
      } else {
        barWidth = barWidth - this.barMarginRight
      }
      const bar = {
        y: currentYPos + this.textHeight,
        width: barWidth
      }
      const res = {
        y: bar.y + (this.textHeight * .60),
        x: bar.width + 1,
        text: option.response || 0
      }
      bars.push({
        idx: option.vid,
        text,
        bar,
        res
      })
    }
    return bars
  }
}
export default Result
