export function cryptoRandomUUID(): string {
  if ('randomUUID' in crypto) {
     /* @ts-ignore */
    return crypto.randomUUID()
  } else {
    return (
      /* @ts-ignore */
      [1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g,
      /* @ts-ignore */
      c => (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    )
  }
}