














import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import BarChart from './BarChart.vue'

@Component({
  components: {
    BarChart
  },
  computed: {
    ...mapGetters({
      poll: 'getPoll',
      respondentId: 'getRespondentId',
      result: 'getResult'
    })
  }
})
class ResultsContent extends Vue {
  name = 'ResultsContent'
  poll:any
  result:any
  respondentId:string | undefined
  colorOpts = [
    '#06334a',
    '#af340f',
    '#4b317c',
    '#0e4d50',
    '#7a6437',
    '#233274',
    '#c39b6d'
  ]
  changed = false
  
  close_time: Date | null = null
  timer: any | null = null
  close_time_suffix: string = ''

  @Watch("$store.state.result")
  private doAnimation() {
    this.changed = true
    setTimeout(() => {
      this.changed = false
    }, 500)
  }

  mounted () {
    if (this.poll !== 404 && this.poll.schedule?.close_time) {
      this.updateCloseTimer()
    }
  }

  updateCloseTimer () {
    this.close_time = new Date(this.poll.schedule.close_time)
    this.close_time_suffix = this.poll.schedule.close_time > new Date().getTime() ? 's' : 'd'
    this.timer = setTimeout(() => {
      this.updateCloseTimer()
    }, 1000)
  }

  beforeDestroy () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }

}
export default ResultsContent
