import Vue from 'vue'
import Vuex from 'vuex'
import GraphQLResult, { API, graphqlOperation } from 'aws-amplify'
import { getPoll, resulgByPoll } from '../graphql/queries'
import { cryptoRandomUUID } from '../helpers/Helpers'
import { onCreatePollResultByPoll } from '../graphql/subscriptions'
import Observable from 'zen-observable-ts';

Vue.use(Vuex)

const store = {
  state: {
    poll: null,
    respondentId: null,
    result: null,
    isPreview: false
  },
  mutations: {
    setPoll (state: any, poll: any) {
      if (poll) {
        if (poll.schedule?.length) {
          poll.schedule = JSON.parse(poll.schedule)
        }
        state.poll = poll
      }
    },
    setRespondentId (state: any) {
      let theId = null
      if (state.poll) {
        theId = Vue.$cookies.get(state.poll.id + state.isPreview)
      }
      state.respondentId = theId || cryptoRandomUUID()
    },
    setResult (state: any, result:any) {
      console.log('do types not match??', state.isPreview, result.isPreview)
      if (state.isPreview !== result.isPreview) {
        return
      }
      result.data = JSON.parse(result.data)
      const questions = Object.assign({}, state.poll.questions)
      for (const question of questions.items) {
        question.vid = question.id + (new Date().getTime())
        delete question.poll
        for (const option of question.options.items) {
          option.vid = option.id + (new Date().getTime())
          if (result && result.data.options[question.id] && result.data.options[question.id][option.id]) {
            option.response = result.data.options[question.id][option.id]
            delete option.question
            delete option.responses
          } else {
            option.response = 0
          }
        }
      }
      delete result.poll
      state.result = Object.assign({}, result, {questions: questions.items})
    },
    setPreview (state: any, isPreview:boolean) {
      state.isPreview = isPreview
    }
  },
  actions: {
    async FetchPoll (context: any, pollId: string) {
      try {
        const pollRes = await API.graphql({
          query: getPoll,
          variables: {
            id: pollId
          }
        })
        if ((pollRes as any).data.getPoll) {
          context.commit('setPoll', (pollRes as any).data.getPoll)
        } else {
          context.commit('setPoll', 404)
        }
      } catch (e) {
        console.error(e)
        if ((e as any).data?.getPoll) {
          context.commit('setPoll', (e as any).data.getPoll)
        } else {
          context.commit('setPoll', 404)
        }
      }
    },
    async SetPreview (context: any, isPreview: boolean) {
      context.commit('setPreview', isPreview)
    },
    async FetchPollResults (context: any, args: {pollId: string, nextToken: string | undefined}) {
      try {
        const pollRes = await API.graphql({
          query: resulgByPoll,
          variables: {
            pollId: args.pollId,
            sortDirection: 'DESC',
            limit: 1,
            filter: {
              isPreview: {
                eq: context.state.isPreview
              }
            },
            nextToken: args.nextToken
          }
        })  as any
        console.log
        if (pollRes.data?.resulgByPoll?.items?.length === 0 && pollRes.data?.resulgByPoll?.nextToken.length !== 0) {
          await context.dispatch('FetchPollResults', {
            pollId: args.pollId, 
            nextToken: pollRes.data?.resulgByPoll?.nextToken
          })
        } else  if (pollRes.data) {
          context.commit('setResult', pollRes.data.resulgByPoll.items[0])
        }
      } catch (e) {
        console.error(e)
        if ((e as any).data?.resulgByPoll?.items[0]) {
          context.commit('setResult', (e as any).data.resulgByPoll.items[0])
        }
      }
    },
    InitRespondentId (context: any) {
      context.commit('setRespondentId')
    },
    InitPollWatch (context: any, pollId: string) {
      const observable = API.graphql(graphqlOperation(/* GraphQL */ `
      subscription OnCreatePollResultByPoll($pollId: String!) {
        onCreatePollResultByPoll(pollId: $pollId) {
          id
          time
          data
          isPreview
          pollId
          createdAt
          updatedAt
        }
      }`, {
        pollId
      })) as Observable<any>
      observable.subscribe({
        next: (res:any) => {
          if (res.value?.data?.onCreatePollResultByPoll) {
            context.commit('setResult', res.value?.data?.onCreatePollResultByPoll)
          }
        },
        error: e => console.log(e)
      })
    }
  },
  getters: {
    getPoll: (state:any) => state.poll,
    getRespondentId: (state:any) => state.respondentId,
    getResult: (state:any) => state.result,
    getIsPollPast: (state:any) => {
      if (new Date().getTime() < state.poll?.schedule?.close_time) {
        return true
      } else {
        return false
      }
    },
    getIsPreview: (state:any) => state.isPreview
  },
}

export default new Vuex.Store(store)
