import { render, staticRenderFns } from "./OpeningSoon.vue?vue&type=template&id=1466f9b2&scoped=true&"
import script from "./OpeningSoon.vue?vue&type=script&lang=ts&"
export * from "./OpeningSoon.vue?vue&type=script&lang=ts&"
import style0 from "./OpeningSoon.vue?vue&type=style&index=0&id=1466f9b2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1466f9b2",
  null
  
)

export default component.exports