


























import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'
import { API } from 'aws-amplify'
import { createResponse } from '../graphql/mutations'
import { CreateResponseInput } from '../services/API'

@Component({
  computed: {
    ...mapGetters({
      poll: 'getPoll',
      respondentId: 'getRespondentId',
      isPreview: 'getIsPreview'
    })
  }
})
class Poll extends Vue {
  name = 'Poll'
  poll:any
  respondentId:string | undefined
  selectedOptions: any = {}
  isPreview: boolean | undefined
  previewPostfix = ''

  mounted () {
    if (this.isPreview) {
      this.previewPostfix = '/preview'
    }
    for (const question of this.poll.questions.items) {
      Vue.set(this.selectedOptions, question.id, [])
    }
  }

  isReady (): boolean {
    for (const ref in this.selectedOptions) {
      const response = this.selectedOptions[ref]
      if (response.length === 0) {
        return false
      }
    }
    return true
  }
 
  async submit () {
    try {
      let err
      for (const questionId in this.selectedOptions) {
        const question = this.selectedOptions[questionId]
        try {
          if (Array.isArray(question)) {
            for (const selectedOption of question) {
              await this.saveResponse(selectedOption)
            }
          } else {
            await this.saveResponse(question)
          }
        } catch (e) {
          err = e
        }
      }
      if (err) {
        throw err
      }
      this.complete()
    } catch (e) {
      if (!(e as any).data?.createResponse) {
        alert('Unable to submit your response, please check your network connection and try again!') 
      } else {
        this.complete()
      }
      console.log(e)
    }
  }

  complete () {
    Vue.$cookies.set(this.poll.id + this.isPreview, this.respondentId!)
    this.$store.dispatch('InitRespondentId')
    this.$router.push('/' + this.poll.id + '/results' + this.previewPostfix )
  }

  async saveResponse(selectedOption:any) {
    const response = {
      optionId: selectedOption.id,
      respondentId: this.respondentId,
      isPreview: this.isPreview
    } as CreateResponseInput
    await API.graphql({
      query: createResponse,
      variables: {
        input: response
      }
    })
  }
}
export default Poll
