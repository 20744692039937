/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createPoll = /* GraphQL */ `
  mutation CreatePoll(
    $input: CreatePollInput!
    $condition: ModelPollConditionInput
  ) {
    createPoll(input: $input, condition: $condition) {
      id
      name
      order
      status
      questions {
        items {
          id
          text
          type
          order
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          options {
            items {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule
      relationId
      relation {
        id
        type
        extId
        polls {
          items {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      results {
        items {
          id
          time
          data
          isPreview
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePoll = /* GraphQL */ `
  mutation UpdatePoll(
    $input: UpdatePollInput!
    $condition: ModelPollConditionInput
  ) {
    updatePoll(input: $input, condition: $condition) {
      id
      name
      order
      status
      questions {
        items {
          id
          text
          type
          order
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          options {
            items {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule
      relationId
      relation {
        id
        type
        extId
        polls {
          items {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      results {
        items {
          id
          time
          data
          isPreview
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePoll = /* GraphQL */ `
  mutation DeletePoll(
    $input: DeletePollInput!
    $condition: ModelPollConditionInput
  ) {
    deletePoll(input: $input, condition: $condition) {
      id
      name
      order
      status
      questions {
        items {
          id
          text
          type
          order
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          options {
            items {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      schedule
      relationId
      relation {
        id
        type
        extId
        polls {
          items {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      results {
        items {
          id
          time
          data
          isPreview
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion(
    $input: CreateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    createQuestion(input: $input, condition: $condition) {
      id
      text
      type
      order
      pollId
      poll {
        id
        name
        order
        status
        questions {
          items {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        schedule
        relationId
        relation {
          id
          type
          extId
          polls {
            items {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        results {
          items {
            id
            time
            data
            isPreview
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      options {
        items {
          id
          text
          order
          questionId
          question {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          responses {
            items {
              id
              optionId
              respondentId
              isPreview
              option {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion(
    $input: UpdateQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    updateQuestion(input: $input, condition: $condition) {
      id
      text
      type
      order
      pollId
      poll {
        id
        name
        order
        status
        questions {
          items {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        schedule
        relationId
        relation {
          id
          type
          extId
          polls {
            items {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        results {
          items {
            id
            time
            data
            isPreview
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      options {
        items {
          id
          text
          order
          questionId
          question {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          responses {
            items {
              id
              optionId
              respondentId
              isPreview
              option {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion(
    $input: DeleteQuestionInput!
    $condition: ModelQuestionConditionInput
  ) {
    deleteQuestion(input: $input, condition: $condition) {
      id
      text
      type
      order
      pollId
      poll {
        id
        name
        order
        status
        questions {
          items {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        schedule
        relationId
        relation {
          id
          type
          extId
          polls {
            items {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        results {
          items {
            id
            time
            data
            isPreview
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      options {
        items {
          id
          text
          order
          questionId
          question {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          responses {
            items {
              id
              optionId
              respondentId
              isPreview
              option {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createOption = /* GraphQL */ `
  mutation CreateOption(
    $input: CreateOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    createOption(input: $input, condition: $condition) {
      id
      text
      order
      questionId
      question {
        id
        text
        type
        order
        pollId
        poll {
          id
          name
          order
          status
          questions {
            items {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule
          relationId
          relation {
            id
            type
            extId
            polls {
              items {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          results {
            items {
              id
              time
              data
              isPreview
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        options {
          items {
            id
            text
            order
            questionId
            question {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            responses {
              items {
                id
                optionId
                respondentId
                isPreview
                option {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      responses {
        items {
          id
          optionId
          respondentId
          isPreview
          option {
            id
            text
            order
            questionId
            question {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            responses {
              items {
                id
                optionId
                respondentId
                isPreview
                option {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateOption = /* GraphQL */ `
  mutation UpdateOption(
    $input: UpdateOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    updateOption(input: $input, condition: $condition) {
      id
      text
      order
      questionId
      question {
        id
        text
        type
        order
        pollId
        poll {
          id
          name
          order
          status
          questions {
            items {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule
          relationId
          relation {
            id
            type
            extId
            polls {
              items {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          results {
            items {
              id
              time
              data
              isPreview
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        options {
          items {
            id
            text
            order
            questionId
            question {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            responses {
              items {
                id
                optionId
                respondentId
                isPreview
                option {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      responses {
        items {
          id
          optionId
          respondentId
          isPreview
          option {
            id
            text
            order
            questionId
            question {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            responses {
              items {
                id
                optionId
                respondentId
                isPreview
                option {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteOption = /* GraphQL */ `
  mutation DeleteOption(
    $input: DeleteOptionInput!
    $condition: ModelOptionConditionInput
  ) {
    deleteOption(input: $input, condition: $condition) {
      id
      text
      order
      questionId
      question {
        id
        text
        type
        order
        pollId
        poll {
          id
          name
          order
          status
          questions {
            items {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule
          relationId
          relation {
            id
            type
            extId
            polls {
              items {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          results {
            items {
              id
              time
              data
              isPreview
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        options {
          items {
            id
            text
            order
            questionId
            question {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            responses {
              items {
                id
                optionId
                respondentId
                isPreview
                option {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      responses {
        items {
          id
          optionId
          respondentId
          isPreview
          option {
            id
            text
            order
            questionId
            question {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            responses {
              items {
                id
                optionId
                respondentId
                isPreview
                option {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createResponse = /* GraphQL */ `
  mutation CreateResponse(
    $input: CreateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    createResponse(input: $input, condition: $condition) {
      id
      optionId
      respondentId
      isPreview
      option {
        id
        text
        order
        questionId
        question {
          id
          text
          type
          order
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          options {
            items {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        responses {
          items {
            id
            optionId
            respondentId
            isPreview
            option {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateResponse = /* GraphQL */ `
  mutation UpdateResponse(
    $input: UpdateResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    updateResponse(input: $input, condition: $condition) {
      id
      optionId
      respondentId
      isPreview
      option {
        id
        text
        order
        questionId
        question {
          id
          text
          type
          order
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          options {
            items {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        responses {
          items {
            id
            optionId
            respondentId
            isPreview
            option {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteResponse = /* GraphQL */ `
  mutation DeleteResponse(
    $input: DeleteResponseInput!
    $condition: ModelResponseConditionInput
  ) {
    deleteResponse(input: $input, condition: $condition) {
      id
      optionId
      respondentId
      isPreview
      option {
        id
        text
        order
        questionId
        question {
          id
          text
          type
          order
          pollId
          poll {
            id
            name
            order
            status
            questions {
              items {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            schedule
            relationId
            relation {
              id
              type
              extId
              polls {
                items {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            results {
              items {
                id
                time
                data
                isPreview
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          options {
            items {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        responses {
          items {
            id
            optionId
            respondentId
            isPreview
            option {
              id
              text
              order
              questionId
              question {
                id
                text
                type
                order
                pollId
                poll {
                  id
                  name
                  order
                  status
                  schedule
                  relationId
                  createdAt
                  updatedAt
                }
                options {
                  nextToken
                }
                createdAt
                updatedAt
              }
              responses {
                items {
                  id
                  optionId
                  respondentId
                  isPreview
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createPollResult = /* GraphQL */ `
  mutation CreatePollResult(
    $input: CreatePollResultInput!
    $condition: ModelPollResultConditionInput
  ) {
    createPollResult(input: $input, condition: $condition) {
      id
      time
      data
      isPreview
      pollId
      poll {
        id
        name
        order
        status
        questions {
          items {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        schedule
        relationId
        relation {
          id
          type
          extId
          polls {
            items {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        results {
          items {
            id
            time
            data
            isPreview
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updatePollResult = /* GraphQL */ `
  mutation UpdatePollResult(
    $input: UpdatePollResultInput!
    $condition: ModelPollResultConditionInput
  ) {
    updatePollResult(input: $input, condition: $condition) {
      id
      time
      data
      isPreview
      pollId
      poll {
        id
        name
        order
        status
        questions {
          items {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        schedule
        relationId
        relation {
          id
          type
          extId
          polls {
            items {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        results {
          items {
            id
            time
            data
            isPreview
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deletePollResult = /* GraphQL */ `
  mutation DeletePollResult(
    $input: DeletePollResultInput!
    $condition: ModelPollResultConditionInput
  ) {
    deletePollResult(input: $input, condition: $condition) {
      id
      time
      data
      isPreview
      pollId
      poll {
        id
        name
        order
        status
        questions {
          items {
            id
            text
            type
            order
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            options {
              items {
                id
                text
                order
                questionId
                question {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                responses {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        schedule
        relationId
        relation {
          id
          type
          extId
          polls {
            items {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        results {
          items {
            id
            time
            data
            isPreview
            pollId
            poll {
              id
              name
              order
              status
              questions {
                items {
                  id
                  text
                  type
                  order
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              schedule
              relationId
              relation {
                id
                type
                extId
                polls {
                  nextToken
                }
                createdAt
                updatedAt
              }
              results {
                items {
                  id
                  time
                  data
                  isPreview
                  pollId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            createdAt
            updatedAt
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createRelation = /* GraphQL */ `
  mutation CreateRelation(
    $input: CreateRelationInput!
    $condition: ModelRelationConditionInput
  ) {
    createRelation(input: $input, condition: $condition) {
      id
      type
      extId
      polls {
        items {
          id
          name
          order
          status
          questions {
            items {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule
          relationId
          relation {
            id
            type
            extId
            polls {
              items {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          results {
            items {
              id
              time
              data
              isPreview
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateRelation = /* GraphQL */ `
  mutation UpdateRelation(
    $input: UpdateRelationInput!
    $condition: ModelRelationConditionInput
  ) {
    updateRelation(input: $input, condition: $condition) {
      id
      type
      extId
      polls {
        items {
          id
          name
          order
          status
          questions {
            items {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule
          relationId
          relation {
            id
            type
            extId
            polls {
              items {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          results {
            items {
              id
              time
              data
              isPreview
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRelation = /* GraphQL */ `
  mutation DeleteRelation(
    $input: DeleteRelationInput!
    $condition: ModelRelationConditionInput
  ) {
    deleteRelation(input: $input, condition: $condition) {
      id
      type
      extId
      polls {
        items {
          id
          name
          order
          status
          questions {
            items {
              id
              text
              type
              order
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              options {
                items {
                  id
                  text
                  order
                  questionId
                  createdAt
                  updatedAt
                }
                nextToken
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          schedule
          relationId
          relation {
            id
            type
            extId
            polls {
              items {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              nextToken
            }
            createdAt
            updatedAt
          }
          results {
            items {
              id
              time
              data
              isPreview
              pollId
              poll {
                id
                name
                order
                status
                questions {
                  nextToken
                }
                schedule
                relationId
                relation {
                  id
                  type
                  extId
                  createdAt
                  updatedAt
                }
                results {
                  nextToken
                }
                createdAt
                updatedAt
              }
              createdAt
              updatedAt
            }
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
