












import { Component, Vue } from 'vue-property-decorator'
import BarChart from './BarChart.vue'
import VueHtml2pdf from 'vue-html2pdf'
import App from '@/App.vue'
import ResultsContent from './ResultsContent.vue'

@Component({
  components: {
    BarChart,
    VueHtml2pdf,
    App,
    ResultsContent
  }
})
class ResultsPdf extends Vue {
  name = 'Result'
  printPdf = false
  html2PdfOpts = {
    padding: 40,
    filename: `poll-result.pdf`,
    image: {
        type: 'jpeg', 
        quality: 0.98
    },
    enableLinks: false,
    html2canvas: {
        scale: 1,
        useCORS: true,
        font: {
          fontFamily: "'Amazon Ember', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif"
        }
    },
    jsPDF: {
        unit: 'in',
        format: 'letter',
        orientation: 'portrait',
        font: "sans-serif"
    }
  }

  mounted () {
    this.$nextTick(() => {
      this.generatePdf()
    })
  }

  generatePdf () {
    this.printPdf = true
    this.$nextTick(() => {
      const result = this.$refs.result as any
      result.generatePdf()
    })
  }
}
export default ResultsPdf
