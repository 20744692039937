







export default {
  name: 'App',
  components: {
  }
}
