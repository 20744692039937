








import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      poll: 'getPoll',
      respondentId: 'getRespondentId',
      result: 'getResult'
    })
  }
})
class OpeningSoon extends Vue {
  name = 'Opening Soon'
  poll:any
  timer:any|null = null
  open_time:Date|null = null
  loadout: boolean = false

  mounted () {
    this.updateTimer()
  }

  updateTimer () {
    this.open_time = new Date(this.poll.schedule.open_time)
    if ((this.poll.schedule.open_time - (2*60*1000)) < new Date().getTime()) {
      this.loadout = true
    }
    this.timer = setTimeout(() => {
      if (this.poll.schedule.open_time < (new Date()).getTime()) {
        this.$router.push('/' + this.poll.id)
      } else {
        this.updateTimer()
      }
    }, 1000)
  }

  beforeDestroy () {
    if (this.timer) {
      clearTimeout(this.timer)
    }
  }
}
export default OpeningSoon
